.swiper-root {
  display: flex;
  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
  line-height: 1.5;
  font-weight: 400;
  color-scheme: light dark;
  color: rgba(255, 255, 255, 0.87);

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
}


body {
  margin: 0;
  font-family: var(--font-scheme),-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat'), url(./fonts/Montserrat/Montserrat-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Montserrat';
    font-weight: 600;
    src: local('Montserrat'), url(./fonts/Montserrat/Montserrat-Medium.ttf) format('truetype');
}

@font-face {
    font-family: 'Montserrat';
    font-weight: 900;
    src: local('Montserrat'), url(./fonts/Montserrat/Montserrat-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'NotoSansSC';
    src: local('NotoSansSC'), url(./fonts/Noto_Sans_SC/NotoSansSC-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'NotoSansSC';
    font-weight: 600;
    src: local('NotoSansSC'), url(./fonts/Noto_Sans_SC/NotoSansSC-Medium.ttf) format('truetype');
}

@font-face {
    font-family: 'NotoSansSC';
    font-weight: 900;
    src: local('NotoSansSC'), url(./fonts/Noto_Sans_SC/NotoSansSC-Bold.ttf) format('truetype');
}

/* Убираем стрелочки в полях ввода числа для Chrome, Safari, Edge и Opera */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Убираем стрелочки в полях ввода числа для Firefox */
input[type=number] {
  -moz-appearance: textfield;
}