.date {
    display: inline-block;
    width: 50%;
}

.time {
    display: inline-block;
    width: 40%;
    margin-left: 10%;
}

