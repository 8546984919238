/* Hide big play button in center of video */
.video-js .vjs-big-play-button {
  display: none;
}

/* Show current playback time on the left of video slider */
.video-js .vjs-time-control{
  display:block;
}

/* Hide hover with current playback time over current playback position in video slider */
.vjs-play-progress>.vjs-time-tooltip {
  opacity: 0;
}
