:root {
  --border-color: #e6e7e9;
}

.panorama-tool-container {
  display: grid;
  grid-template-rows:  minmax(60px, max-content) 1fr 60px;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    "secondary-header secondary-header"
    "panel-left panel-right"
    "footer-left footer-right";
}
.panorama-tool-container > * {
  border: 0px solid var(--border-color);
  background-color: white;
}
.panorama-tool-container .primary-header {
  grid-area: primary-header;
  border-bottom-width: 1px;
  display: flex;
  background: #bac8da;
  align-items: center;
  justify-content: space-between;
  padding-left: 40px;
  padding-right: 40px;
}
.panorama-tool-container .secondary-header {
  grid-area: secondary-header;
  display: flex;

}
.panorama-tool-container .preview-panel-left {
  grid-area: panel-left;
}
.panorama-tool-container .preview-panel-right {
  grid-area: panel-right;
}
.panorama-tool-container .footer-left {
  grid-area: footer-left;
}
.panorama-tool-container .footer-right {
  grid-area: footer-right;
}
.panorama-tool-container .footer {
  border-top-width: 1px;
  display: flex;
  justify-content: center;
}
.panorama-tool-container .footer > * {
  margin: 2px;
}
